import axios from "axios";

const API_USER_URL = process.env.REACT_APP_API_USER_URL;

const api = axios.create({
    baseURL: API_USER_URL,
});

const VIDALINK_SESSION_ID = process.env.REACT_APP_VIDALINK_SESSION_ID;
const CUSTOM_HEADER_KEY : string = process.env.REACT_APP_CUSTOM_HEADER_KEY as string;
const CUSTOM_HEADER_VALUE : string = process.env.REACT_APP_CUSTOM_HEADER_VALUE as string;

api.interceptors.request.use((config) => {
    try {
        const token = localStorage.getItem("accessToken");

        if (config.headers) {
            config.headers.Authorization = `Bearer ${token}`;
            config.headers["Vidalink-Session-Id"] = VIDALINK_SESSION_ID;
            config.headers[CUSTOM_HEADER_KEY] = CUSTOM_HEADER_VALUE;
        }

        return config;
    } catch (err) {
        throw Error("No token");
    }
});

export function getUserBySocialId(id: string) {
    return api.get(`${API_USER_URL}?socialId=${id}`);
}

export function getUserByCardNumber(cardNumber: string) {
    return api.get(`${API_USER_URL}/${cardNumber}`);
}
