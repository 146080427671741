// @ts-nocheck
import { ChangeEvent, FormEvent, Fragment, useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import { format } from "date-fns";
import { Combobox, Transition } from "@headlessui/react";
import DatePicker from "react-date-picker";
import { toast, Toaster } from "react-hot-toast";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { validatePrescriptionProduct } from "services/api/prescription/prescriptionService";
import { Card } from "components/widgets/organisms/Card";
import {
    getProductsWithoutPlanFilters,
    getProductInformation,
} from "services/api/product/productService";
import {
    addApprovedProduct,
    addApprovedSimilarProduct,
    isPrescriptionPending,
    updatePrescriptionDate,
    updateRegionalCouncil,
    updateStateRegionalCouncil,
    updateTypeCouncil,
    setLoadingDetailPageOn,
    setLoadingDetailPageOff,
} from "store/modules/prescriptionDetail";

import { setLoadingOff, setLoadingOn } from "store/modules/loader";

import { ImageGallery } from "components/widgets/molecules/ImageGallery";

import { Toast, ToastType } from "components/widgets/atoms/Toast";
import { getDoctorName } from "services/api/specialist/specialistService";
import { el } from "date-fns/locale";
import { PrescriptionProduct } from "types/Product";
import { useAprovedProductsData } from "hooks/useAprovedProductsData";

type Product = {
    identification: {
        code: string;
        type: string;
    };
    information?: {
        sku: string;
        classification: {
            isUnauthorized?: boolean;
        };
        attributes: Array;
    };
    name: string;
    status: string;
};

type Props = {
    imagesPath: Array<string>;
    validateWarnStatus: Function;
};

enum CouncilType {
    CRM = "CRM",
    CRO = "CRO",
}

export function RecipeCheck({ imagesPath, validateWarnStatus }: Props) {
    const dispatch = useAppDispatch();
    const { isLoading } = useAppSelector((state) => state.loader);

    const prescriptionDetailSelector = useAppSelector((state) => state.prescriptionDetail);

    const { data } = prescriptionDetailSelector;

    const aprovedProducts = useAprovedProductsData();

    const {
        stateRegionalCouncil,
        regionalCouncil,
        prescriptionDate: rawDate,
        regionalCouncilType,
        products,
        protocol,
        status,
        clientGroup,
        customerId,
        cardNumber,
        ocrData,
        iAConfig,
    } = data;

    const labels = [
        "tipo_do_conselho_crm_ou_cro",
        "numero_do_conselho",
        "uf_do_conselho",
        "data_da_receita",
    ];

    const ocrResults = ocrData?.filter((crop) => labels.includes(crop.label));
    const ocrResultsReduced = ocrResults?.reduce((acc, next) => {
        acc[next.label] = next.ocr;
        return acc;
    }, {});

    const isPending = useAppSelector(isPrescriptionPending);
    const [doctorName, setDoctorName] = useState<string>("");
    const [crm, setCrm] = useState<number | "">(regionalCouncil);
    const [crmToSearch, setCrmToSearch] = useState<number | "">(crm);
    const [UF, setUF] = useState(stateRegionalCouncil || "AC");
    const [date, setDate] = useState<Date | null>();
    const [councilWarn, setCouncilWarn] = useState<Boolean>(false);
    const [dosage, setDosage] = useState<number>(1);
    const [recipeStatus] = useState<string>(status?.status ?? "");
    const [firstCouncilValue, setFirstCouncilValue] = useState<Boolean>(true);

    const firstValue = regionalCouncil;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceCRM = useCallback(
        debounce((value) => {
            setCrmToSearch(value);
        }, 1000),
        []
    );

    useEffect(() => {
        if (!protocol) return;

        if (!stateRegionalCouncil) {
            setUF("AC");
        } else {
            setUF(stateRegionalCouncil);
        }

        setCrm(regionalCouncil);
        debounceCRM(regionalCouncil);

        if (rawDate) {
            // const prescriptionDate = format(new Date(rawDate), "yyyy-MM-dd");
            if (rawDate !== "0001-01-01T00:00:00Z") {
                setDate(new Date(rawDate.replace("Z", "")));
            }
        }
    }, [debounceCRM, protocol, rawDate, regionalCouncil, stateRegionalCouncil]);

    useEffect(() => {
        setFirstCouncilValue(true);

        if (firstValue === crmToSearch && crmToSearch != 0) {
            setFirstCouncilValue(false);
        }
    }, [crmToSearch, firstValue]);

    const [query, setQuery] = useState("");
    const [productsList, setProductsList] = useState<Array<Product>>([]);
    const [selectedProduct, setSelectedProduct] = useState<Product>();
    const [productWithDetails, setProductWithDetails] = useState<Product | null>();
    const [product, setProduct] = useState<Product | null>();
    const [ean, setEAN] = useState("");

    function handleCouncilWarning(warnValue, recipeStatusParam) {
        if (recipeStatus === "AnalisyPending" || recipeStatusParam === "AnalisyPending") {
            validateWarnStatus(warnValue);
            setCouncilWarn(warnValue);
        } else {
            setCouncilWarn(false);
        }
    }

    function handleUpdateUF(event: ChangeEvent<HTMLSelectElement>) {
        const { value } = event.target;

        setUF(value);
        dispatch(updateStateRegionalCouncil(value));
    }

    function handlePaste(event: React.ClipboardEvent<HTMLInputElement>) {
        const pastedData = event.clipboardData.getData("text");

        // Verifique se o valor colado é numérico
        if (/^\d+$/.test(pastedData)) {
            // O valor colado é numérico, então podemos permitir que a ação de colar seja executada
            return;
        } else {
            // O valor colado não é numérico, então cancelamos a ação de colar
            event.preventDefault();
        }
    }

    function filterNonNumericInput(event: React.FormEvent<HTMLInputElement>) {
        const charCode = event.key;

        // Verifica se o usuário pressionou as teclas CTRL + V
        if (event.ctrlKey && event.key === "v") {
            return;
        }

        // Se a tecla pressionada for +, -, ou ., cancelar o evento
        if (["+", "-", ".", ","].includes(charCode)) {
            event.preventDefault();
        }
    }

    function handleUpdateRegionalCouncil(event: ChangeEvent<HTMLInputElement>) {
        handleCouncilWarning(false);

        let value = event.target.value;

        const intValue = parseInt(value);

        if (councilType === "CRM" && (value === "0" || value.startsWith("00"))) {
            setDoctorName("NOME INDISPONÍVEL");
            handleCouncilWarning(true);
            setCrm(0);
            return;
        }

        if (councilType === "CRM" && !intValue) {
            setDoctorName("NOME INDISPONÍVEL");
            handleCouncilWarning(true);
            setCrm("");
            return;
        }

        setCrm(intValue);
        dispatch(updateRegionalCouncil(intValue));
    }

    function handleUpdateDate(newDate) {
        dispatch(updatePrescriptionDate(newDate));

        setDate(newDate);
    }

    function handleUpdateDosage(event: ChangeEvent<HTMLInputElement>) {
        const { value } = event.target;

        if (value > 999) return;

        setDosage(value);
    }

    const [councilType, setCouncilType] = useState<CouncilType>(() => {
        switch (regionalCouncilType) {
            case 1:
                return CouncilType.CRM;
            case 2:
                return CouncilType.CRO;
            default:
                return CouncilType.CRM;
        }
    });

    const fetchProducts = useCallback(async (query: string) => {
        try {
            const response = await getProductsWithoutPlanFilters({
                search: query,
                from: 1,
                limit: 40,
            });

            const filteredResults = response?.data?.data?.filter((item) => {
                const matchSKUWithQuery =
                    item?.information?.sku?.toUpperCase().indexOf(query.toUpperCase()) !== -1;
                const isSameEAN = query === item.identification.code;

                return matchSKUWithQuery || isSameEAN;
            });

            return filteredResults;
        } catch (err) {
            console.error(err);
            return [];
        }
    }, []);

    const fetchProductsAndUpdateState = useCallback(
        async (query: string) => {
            try {
                dispatch(setLoadingOn());

                setProductsList(await fetchProducts(query));
            } catch (error) {
                console.error(error);
            } finally {
                dispatch(setLoadingOff());
            }
        },
        [dispatch, fetchProducts]
    );

    async function fetchProductDetails(product: Product) {
        try {
            const { data } = await getProductInformation({
                cardNumber,
                customerId,
                search: product.identification?.code,
            });

            if (data != null) {
                const filteredDetailsResults = data?.data;
                if (
                    filteredDetailsResults[0].identification.code === product.identification?.code
                ) {
                    setProduct((prevState) => ({
                        ...prevState,
                        information: filteredDetailsResults[0].information,
                    }));
                }
                return filteredDetailsResults[0];
            } else {
                return product;
            }
        } catch (err) {
            return product;
        }
    }

    async function handleAddProduct(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();

        const invalidRegionalCouncil = crm === 0;
        // comentado temporariamente essa regra de negócio
        // const invalidDate = !date || new Date(date) < new Date(2018, 2);
        const invalidDate = !date;

        if (invalidDate || invalidRegionalCouncil || !UF || !crm || !dosage) {
            return toast.custom(
                (t) => <Toast t={t} text="Informe os dados da receita" type={ToastType.Error} />,
                { id: "invalid-data" }
            );
        }

        if (!product) return;

        try {
            dispatch(setLoadingDetailPageOn());

            const product_ref = await fetchProductDetails(product);
            setProduct(product_ref);
            setProductWithDetails(product_ref);

            const response = await validatePrescriptionProduct({
                clientGroup,
                clientId: clientGroup,
                customerId,
                ean,
                prescriptionDate: format(date, "yyyy-MM-dd"),
                regionalCouncilNumber: crm,
                stateRegionalCouncil: UF,
                productStatus: Boolean(product_ref.information?.classification?.isUnauthorized)
                    ? "Reproved"
                    : "Approved",
                cardNumber: cardNumber,
                dosage,
            });

            if (!response || response?.status >= 300) {
                return toast.custom(
                    (t) => (
                        <Toast t={t} text={"Erro ao adicionar produto"} type={ToastType.Error} />
                    ),
                    {
                        id: "unknown-error",
                    }
                );
            }

            const idProductStatus = Boolean(product_ref.information?.classification?.isUnauthorized)
                ? 2
                : 1;

            const approvedProduct = {
                ean: product_ref.identification.code,
                name: product_ref.information.sku,
                idProductStatus,
                productStatus: Boolean(product_ref.information?.classification?.isUnauthorized)
                    ? "Reproved"
                    : "Approved",
                validDate: true,
                dosage,
                attributes: product_ref.information?.attributes,
                classification: product_ref.information?.classification,
            };

            dispatch(addApprovedProduct(approvedProduct));
            setProduct(null);
            setDosage(1);
            setProductQuery("");
            setProductToSearch("");
            setEAN("");
        } catch (err) {
            return toast.custom((t) => <Toast t={t} text={"Erro"} type={ToastType.Error} />, {
                id: "unknown-error",
            });
        } finally {
            dispatch(setLoadingDetailPageOff());
        }
    }

    const fetchDoctorName = useCallback(
        async ({ councilNumber, councilUF, recipeStatusParam }) => {
            if (!recipeStatusParam) return;
            if (councilType === CouncilType.CRO) return;
            if (councilNumber === 0 && !firstCouncilValue)
                return handleCouncilWarning(true, recipeStatusParam);

            try {
                handleCouncilWarning(false, recipeStatusParam);

                const response = await getDoctorName({
                    number: councilNumber,
                    state: councilUF,
                });

                const { data, message } = response.data;

                if (message === "MEDVALIDO") {
                    setDoctorName(data?.name || "NOME INDISPONÍVEL");
                    handleCouncilWarning(false, recipeStatusParam);
                } else {
                    setDoctorName("NOME INDISPONÍVEL");
                    handleCouncilWarning(true, recipeStatusParam);
                }
            } catch (err) {
                setDoctorName("NOME INDISPONÍVEL");
                handleCouncilWarning(true, recipeStatusParam);
            }
        },
        [councilType]
    );

    const loadDoctorName = useCallback(async () => {
        await fetchDoctorName({
            councilNumber: regionalCouncil,
            councilUF: stateRegionalCouncil,
            recipeStatusParam: status?.status,
        });
    }, [fetchDoctorName, regionalCouncil, stateRegionalCouncil, status?.status]);

    useEffect(() => {
        loadDoctorName();
    }, [loadDoctorName]);

    useEffect(() => {
        dispatch(updateTypeCouncil(regionalCouncilType));
        let ocrTipoDoConselho = "";

        if (ocrResultsReduced) {
            ocrTipoDoConselho = ocrResultsReduced["tipo_do_conselho_crm_ou_cro"];
        }

        if (!ocrTipoDoConselho || status.status !== "AnalisyPending") {
            if (regionalCouncilType === 2) {
                setCouncilType(CouncilType.CRO);
            } else {
                setCouncilType(CouncilType.CRM);
            }
        }
    }, [dispatch, regionalCouncilType]);

    function handleCouncilTypeChange(event: ChangeEvent<HTMLSelectElement>) {
        const { value } = event.target;

        if (value === CouncilType.CRM) {
            dispatch(updateTypeCouncil(1));
            setCouncilType(CouncilType.CRM);
        }
        if (value === CouncilType.CRO) {
            dispatch(updateTypeCouncil(2));
            setCouncilType(CouncilType.CRO);
        }
    }

    useEffect(() => {
        const isTypeCRM = councilType === CouncilType.CRM;
        const hasNumberToSearch = Number.isInteger(crmToSearch);
        const hasUF = Boolean(UF);
        const hasRecipeStatus = !!(recipeStatus && recipeStatus.trim());

        if (
            isTypeCRM &&
            hasNumberToSearch &&
            hasUF &&
            protocol &&
            hasRecipeStatus &&
            !firstCouncilValue
        ) {
            fetchDoctorName({
                councilNumber: crmToSearch,
                councilUF: UF,
                recipeStatusParam: recipeStatus,
            });
        }
    }, [UF, councilType, crmToSearch, recipeStatus, firstCouncilValue]);

    const [productToSearch, setProductToSearch] = useState("");
    const [productQuery, setProductQuery] = useState("");

    function handleSearchProduct(event) {
        const { value } = event.target;
        const { inputType } = event.nativeEvent;

        setProductToSearch(value);

        if (
            inputType != "deleteContentBackward" &&
            inputType != "deleteContentForward" &&
            value.length >= 3
        ) {
            debounceSearch(value);
        }

        const erase = value.length === 0;

        if (erase) {
            setEAN("");
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceSearch = useCallback(
        debounce((value) => {
            setProductQuery(value);
        }, 500),
        []
    );

    useEffect(() => {
        if (productQuery.length === 0) return;

        fetchProductsAndUpdateState(productQuery);
    }, [fetchProductsAndUpdateState, productQuery]);

    async function fetchProductDetails(
        product: PrescriptionProduct
    ): Promise<PrescriptionProduct | null> {
        try {
            const searchKey = product.isGeneric
                ? product.information.substanceActive
                : product.identification?.code;

            const { data } = await getProductInformation({
                cardNumber,
                customerId,
                search: searchKey,
                isGeneric: product.isGeneric,
            });

            if (data != null) {
                const filteredDetailsResults = data?.data;
                if (
                    filteredDetailsResults[0].identification.code === product.identification?.code
                ) {
                    setProduct((prevState) => ({
                        ...prevState,
                        information: filteredDetailsResults[0].information,
                    }));
                }
                return filteredDetailsResults[0];
            }
        } catch (err) {
            if (err?.response?.status === 404) {
                return null;
            } else {
                return toast.custom(
                    (t) => <Toast t={t} text={"Erro no servidor!"} type={ToastType.Error} />,
                    {
                        id: "not-found-generic-medications",
                    }
                );
            }
        }
    }

    async function handleSelectProduct(product: Product) {
        setProduct(product);
        setProductToSearch(product.information?.sku);
        setEAN(product.identification?.code);
    }

    const handleClearItensData = async () => {
        setProduct(null);
        setDosage(1);
        setProductQuery("");
        setProductToSearch("");
        setEAN("");
    };

    async function handleAddProduct(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();

        const invalidRegionalCouncil = crm === 0;
        // comentado temporariamente essa regra de negócio
        // const invalidDate = !date || new Date(date) < new Date(2018, 2);
        const invalidDate = !date;

        if (invalidDate || invalidRegionalCouncil || !UF || !crm || !dosage) {
            return toast.custom(
                (t) => <Toast t={t} text="Informe os dados da receita" type={ToastType.Error} />,
                { id: "invalid-data" }
            );
        }

        if (!product) return;

        try {
            dispatch(setLoadingDetailPageOn());

            const product_ref = await fetchProductDetails(product);

            let prevSimilarProduct: PrescriptionProduct;

            if (product_ref.information.classification.isReference) {
                prevSimilarProduct = await fetchProductDetails({
                    ...product,
                    information: {
                        substanceActive: product_ref.information.substanceActive,
                    },
                    isGeneric: true,
                });

                if (!prevSimilarProduct) {
                    toast.custom(
                        (t) => (
                            <Toast
                                t={t}
                                text={"Não há opções genéricas para este medicamento"}
                                type={ToastType.Error}
                            />
                        ),
                        {
                            id: "not-found-generic",
                        }
                    );
                }
            }

            setProduct(product_ref);
            setProductWithDetails(product_ref);

            const response = await validatePrescriptionProduct({
                clientGroup,
                clientId: clientGroup,
                customerId,
                ean,
                prescriptionDate: format(date, "yyyy-MM-dd"),
                regionalCouncilNumber: crm,
                stateRegionalCouncil: UF,
                productStatus: Boolean(product_ref.information?.classification?.isUnauthorized)
                    ? "Reproved"
                    : "Approved",
                cardNumber: cardNumber,
                dosage,
            });

            if (!response || response?.status >= 300) {
                return toast.custom(
                    (t) => (
                        <Toast t={t} text={"Erro ao adicionar produto"} type={ToastType.Error} />
                    ),
                    {
                        id: "unknown-error",
                    }
                );
            }

            const idProductStatus = Boolean(product_ref.information?.classification?.isUnauthorized)
                ? 2
                : 1;

            const approvedProduct = {
                ean: product_ref.identification.code,
                name: product_ref.information.sku,
                idProductStatus,
                productStatus: Boolean(product_ref.information?.classification?.isUnauthorized)
                    ? "Reproved"
                    : "Approved",
                validDate: true,
                dosage,
                attributes: product_ref.information?.attributes,
                classification: product_ref.information?.classification,
                isSingularProduct: true,
            };

            if (product_ref.information.classification.isReference && prevSimilarProduct) {
                const approvedProductIsReference = {
                    ean: product_ref.identification.code,
                    name: product_ref.information.sku,
                    idProductStatus,
                    productStatus: Boolean(product_ref.information?.classification?.isUnauthorized)
                        ? "Reproved"
                        : "Approved",
                    validDate: true,
                    dosage,
                    attributes: product_ref.information?.attributes,
                    classification: product_ref.information?.classification,
                    uuid: `${product_ref.identification.code}${prevSimilarProduct.identification.code}`,
                };

                const approvedSimilarProduct = {
                    eanReference: product_ref.identification.code,
                    ean: prevSimilarProduct.identification.code,
                    name: prevSimilarProduct.information.sku,
                    idProductStatus,
                    productStatus: prevSimilarProduct.information?.classification?.isUnauthorized
                        ? "Reproved"
                        : "Approved",
                    validDate: true,
                    dosage,
                    attributes: prevSimilarProduct.information?.attributes,
                    classification: prevSimilarProduct.information?.classification,
                    uuid: `${product_ref.identification.code}${prevSimilarProduct.identification.code}`,
                    isApprovedSimilarProduct: true,
                };

                const eanReferenceAlreadyInserted = aprovedProducts
                    ? aprovedProducts.find(({ ean }) => ean === approvedProduct.ean)
                    : false;

                const eanGenericAlreadyInserted = aprovedProducts
                    ? aprovedProducts.find(({ ean }) => ean === approvedSimilarProduct.ean)
                    : false;

                if (!eanReferenceAlreadyInserted && !eanGenericAlreadyInserted) {
                    dispatch(
                        addApprovedProduct([approvedProductIsReference, approvedSimilarProduct])
                    );
                } else if (eanGenericAlreadyInserted) {
                    await handleClearItensData();
                    return toast.custom(
                        (t) => (
                            <Toast
                                t={t}
                                text={`EAN ${approvedSimilarProduct.ean} já inserido como genérico e não será adicionado novamente`}
                                type={ToastType.Error}
                            />
                        ),
                        {
                            id: "ean-generic-already-inserted",
                        }
                    );
                }
            } else {
                const eanReferenceAlreadyInserted = aprovedProducts
                    ? aprovedProducts.find(({ ean }) => ean === approvedProduct.ean)
                    : false;

                if (!eanReferenceAlreadyInserted) {
                    dispatch(addApprovedProduct([approvedProduct]));
                } else if (
                    eanReferenceAlreadyInserted &&
                    approvedProduct.classification.isGeneric
                ) {
                    await handleClearItensData();
                    return toast.custom(
                        (t) => (
                            <Toast
                                t={t}
                                text={`EAN ${approvedProduct.ean} já inserido como genérico e não será adicionado novamente`}
                                type={ToastType.Error}
                            />
                        ),
                        {
                            id: "ean-generic-already-inserted",
                        }
                    );
                } else {
                    await handleClearItensData();
                    return toast.custom(
                        (t) => <Toast t={t} text={`EAN já inserido`} type={ToastType.Error} />,
                        {
                            id: "ean-reference-already-inserted",
                        }
                    );
                }
            }

            await handleClearItensData();
        } catch (err) {
        } finally {
            dispatch(setLoadingDetailPageOff());
        }
    }

    return (
        <Card>
            <Toaster
                data-testid="snackbar"
                position="bottom-center"
                containerStyle={{
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                }}
                toastOptions={{
                    duration: 3000,
                }}
            />
            <h3 className="mb-6 text-[2rem] font-bold text-vidalink-blue">
                Verificação da receita
            </h3>

            <div className="grid grid-cols-2 gap-4">
                <div className="max-h-[800px] overflow-scroll px-1">
                    <strong className="mb-6 inline-block text-2xl font-bold text-vidalink-gray-700">
                        Dados da receita
                    </strong>

                    <div className="mb-6">
                        <h4 className="text-xl font-bold text-vidalink-blue">
                            Medicamentos da Receita
                        </h4>
                        <div className="mt-2 space-y-2">
                            {products?.map((product, index) => {
                                return (
                                    <input
                                        key={product}
                                        type="text"
                                        className="w-full"
                                        disabled
                                        value={`${index + 1}. ${product}`}
                                    />
                                );
                            })}
                        </div>
                    </div>

                    <div className="mb-6">
                        <h4 className="text-xl font-bold text-vidalink-blue">Nome do médico</h4>
                        <input type="text" className="mt-2 w-full" disabled value={doctorName} />
                    </div>

                    <div className="mb-6 grid grid-cols-2 justify-between gap-3">
                        {/* Campo OCR: tipo_do_conselho_crm_ou_cro */}
                        <div className="form-control flex-1 gap-3">
                            <label htmlFor="council_type">Tipo do conselho</label>
                            {status?.status === "AnalisyPending" &&
                                ocrResults
                                    ?.filter((result: any) => {
                                        return result.label === "tipo_do_conselho_crm_ou_cro";
                                    })
                                    ?.map((filteredResult, index) => (
                                        <small
                                            className={
                                                filteredResult?.ocr_score >=
                                                iAConfig?.thresholdTipoConselho
                                                    ? "font-medium text-vidalink-green"
                                                    : "font-medium text-vidalink-magenta"
                                            }
                                            key={index}
                                        >
                                            {Math.floor(filteredResult.ocr_score * 100)}% confiável
                                            -{" "}
                                            {filteredResult?.ocr_score >=
                                            iAConfig?.thresholdTipoConselho
                                                ? "APROVADO"
                                                : "REPROVADO"}
                                        </small>
                                    ))}

                            <select
                                name="council_type"
                                id="council_type"
                                onChange={handleCouncilTypeChange}
                                disabled={!isPending}
                            >
                                <option value="CRM" selected={councilType === "CRM"}>
                                    CRM
                                </option>
                                <option value="CRO" selected={councilType === "CRO"}>
                                    CRO
                                </option>
                            </select>
                        </div>

                        {/* Campo OCR: numero_do_conselho */}
                        <div className="form-control flex-1 gap-3">
                            <label htmlFor="council_number">Nº do conselho</label>
                            {status?.status === "AnalisyPending" &&
                                ocrResults
                                    ?.filter((result: any) => {
                                        return result.label === "numero_do_conselho";
                                    })
                                    ?.map((filteredResult, index) => (
                                        <small
                                            className={
                                                filteredResult?.ocr_score >=
                                                iAConfig?.thresholdNConselho
                                                    ? "font-medium text-vidalink-green"
                                                    : "font-medium text-vidalink-magenta"
                                            }
                                            key={index}
                                        >
                                            {Math.floor(filteredResult.ocr_score * 100)}% confiável
                                            -{" "}
                                            {filteredResult?.ocr_score >=
                                            iAConfig?.thresholdNConselho
                                                ? "APROVADO"
                                                : "REPROVADO"}
                                        </small>
                                    ))}
                            <input
                                className={councilWarn ? "border-vidalink-magenta" : ""}
                                type="number"
                                name="council_number"
                                id="council_number"
                                min={0}
                                value={crm}
                                onChange={handleUpdateRegionalCouncil}
                                onKeyDown={filterNonNumericInput}
                                onPaste={handlePaste}
                                disabled={!isPending}
                            />
                            {councilWarn ? (
                                <small className="text-sm font-bold text-vidalink-magenta">
                                    Nº inválido ou inexistente
                                </small>
                            ) : null}
                        </div>
                    </div>

                    <div className="mb-6 grid grid-cols-2 justify-between gap-3">
                        {/* Campo OCR: uf_do_conselho */}
                        <div className="form-control flex-1 gap-3">
                            <label htmlFor="uf">UF do conselho</label>
                            {status?.status === "AnalisyPending" &&
                                ocrResults
                                    ?.filter((result: any) => {
                                        return result.label === "uf_do_conselho";
                                    })
                                    ?.map((filteredResult, index) => (
                                        <small
                                            className={
                                                filteredResult?.ocr_score >=
                                                iAConfig?.thresholdUfConselho
                                                    ? "font-medium text-vidalink-green"
                                                    : "font-medium text-vidalink-magenta"
                                            }
                                            key={index}
                                        >
                                            {Math.floor(filteredResult.ocr_score * 100)}% confiável
                                            -{" "}
                                            {filteredResult?.ocr_score >=
                                            iAConfig?.thresholdUfConselho
                                                ? "APROVADO"
                                                : "REPROVADO"}
                                        </small>
                                    ))}
                            <select
                                name="uf"
                                id="uf"
                                value={UF}
                                onChange={handleUpdateUF}
                                disabled={!isPending}
                            >
                                <option value="AC">AC</option>
                                <option value="AL">AL</option>
                                <option value="AP">AP</option>
                                <option value="AM">AM</option>
                                <option value="BA">BA</option>
                                <option value="CE">CE</option>
                                <option value="DF">DF</option>
                                <option value="ES">ES</option>
                                <option value="GO">GO</option>
                                <option value="MA">MA</option>
                                <option value="MT">MT</option>
                                <option value="MS">MS</option>
                                <option value="MG">MG</option>
                                <option value="PA">PA</option>
                                <option value="PB">PB</option>
                                <option value="PR">PR</option>
                                <option value="PE">PE</option>
                                <option value="PI">PI</option>
                                <option value="RJ">RJ</option>
                                <option value="RN">RN</option>
                                <option value="RS">RS</option>
                                <option value="RO">RO</option>
                                <option value="RR">RR</option>
                                <option value="SC">SC</option>
                                <option value="SP">SP</option>
                                <option value="SE">SE</option>
                                <option value="TO">TO</option>
                            </select>
                        </div>

                        {/* Campo OCR: data_da_receita */}
                        <div className="form-control mb-6 flex-1 gap-3">
                            <label
                                htmlFor="date"
                                style={{
                                    marginBottom:
                                        status?.status === "AnalisyPending" &&
                                        ocrResults?.find(
                                            (result: any) => result.label === "uf_do_conselho"
                                        )
                                            ? "30px"
                                            : "0px",
                                }}
                            >
                                {" "}
                                Data da receita{" "}
                            </label>
                            <DatePicker
                                className="rounded-xl border border-vidalink-blue px-4 py-3 text-xl outline-none focus:ring-2 focus:ring-vidalink-blue disabled:bg-vidalink-gray-100"
                                value={date}
                                onChange={handleUpdateDate}
                                maxDate={new Date()}
                                format="dd/MM/y"
                                locale="pt-BR"
                                id="date"
                                disabled={!isPending}
                                readOnly={true}
                                onKeyDown={(e) => e.preventDefault()}
                            />
                        </div>
                    </div>

                    <strong className="mb-6 inline-block text-2xl font-bold text-vidalink-gray-700">
                        Adicionar Medicamentos
                    </strong>

                    <form onSubmit={handleAddProduct} autoComplete="off">
                        <div className="mb-6 flex justify-between gap-3">
                            <div className="form-control mb-6 w-3/4 flex-1">
                                <label htmlFor="date">Medicamento*</label>
                                <Combobox
                                    value={selectedProduct}
                                    onChange={handleSelectProduct}
                                    disabled={!isPending || isLoading}
                                >
                                    <div className="relative mt-1">
                                        <div className="relative">
                                            <Combobox.Input
                                                className="form-control w-full"
                                                value={productToSearch}
                                                onChange={handleSearchProduct}
                                                required
                                            />
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                            afterLeave={() => setQuery("")}
                                        >
                                            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                {productsList.length === 0 && query !== "" ? (
                                                    <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                                                        Nenhum resultado encontrado
                                                    </div>
                                                ) : (
                                                    productsList?.map((product) => (
                                                        <Combobox.Option
                                                            key={product.identification.code}
                                                            className={({ active }) =>
                                                                `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                                                    active
                                                                        ? "bg-vidalink-blue text-white"
                                                                        : "text-gray-900"
                                                                }`
                                                            }
                                                            value={product}
                                                        >
                                                            {({ selected, active }) => (
                                                                <>
                                                                    <span
                                                                        className={`block truncate text-black ${
                                                                            selected
                                                                                ? "font-medium"
                                                                                : "font-normal"
                                                                        }`}
                                                                    >
                                                                        {product.information.sku}
                                                                    </span>
                                                                    {selected ? (
                                                                        <span
                                                                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                                                                active
                                                                                    ? "text-white"
                                                                                    : "text-vidalink-blue"
                                                                            }`}
                                                                        ></span>
                                                                    ) : null}
                                                                </>
                                                            )}
                                                        </Combobox.Option>
                                                    ))
                                                )}
                                            </Combobox.Options>
                                        </Transition>
                                    </div>
                                </Combobox>
                            </div>
                            <div className="form-control w-1/4 gap-3">
                                <label htmlFor="dosage_number">Posologia*</label>
                                <input
                                    type="number"
                                    name="dosage_number"
                                    id="dosage_number"
                                    min={1}
                                    max={999}
                                    value={dosage}
                                    onChange={handleUpdateDosage}
                                    onKeyDown={filterNonNumericInput}
                                    onPaste={handlePaste}
                                    disabled={!isPending}
                                />
                            </div>
                        </div>
                        <div className="form-control mb-6 flex-1">
                            <label htmlFor="ean">EAN* </label>
                            <input
                                type="text"
                                placeholder="000000000"
                                required
                                value={ean}
                                disabled
                            />
                        </div>

                        <button
                            type="submit"
                            className="w-full rounded-3xl bg-vidalink-blue p-3 font-bold text-white"
                            disabled={!isPending}
                        >
                            Adicionar medicamento
                        </button>
                    </form>
                </div>

                <ImageGallery images={imagesPath} />
            </div>
        </Card>
    );
}
